import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Menu Bar",
  "path": "/Web_User_Interface/720p_Series/Menu_Bar/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Menu Bar",
  "social": "/images/Search/QI_SearchThumb_WebUI_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Menu Bar :: INSTAR HD Web User Interface' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Menu Bar is located above the live video and allows you to quickly access the SD card, take snapshots or start recordings or activate the motion detection of your camera.' image='/images/Search/QI_SearchThumb_WebUI_720p.png' twitter='/images/Search/QI_SearchThumb_WebUI_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Menu_Bar/' locationFR='/fr/Web_User_Interface/720p_Series/Menu_Bar/' crumbLabel="Menu Bar" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "menu-bar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#menu-bar",
        "aria-label": "menu bar permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Menu Bar`}</h2>
    <h3 {...{
      "id": "sd-card---open-your-cameras-sd-card-directory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sd-card---open-your-cameras-sd-card-directory",
        "aria-label": "sd card   open your cameras sd card directory permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SD Card - Open your cameras SD card directory`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/5a190/WebUI-MenuBar_SD-Card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.6521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAANklEQVQI1xXBgQmAQAwDQPefoEpCiwklbil/d5GsKtnTPTO2P2Ke+5V8CABJSUm6G4DtJLv7A3e7JbzO4CIrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/e4706/WebUI-MenuBar_SD-Card.avif 230w", "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/d1af7/WebUI-MenuBar_SD-Card.avif 460w", "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/c9875/WebUI-MenuBar_SD-Card.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/a0b58/WebUI-MenuBar_SD-Card.webp 230w", "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/bc10c/WebUI-MenuBar_SD-Card.webp 460w", "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/d00b9/WebUI-MenuBar_SD-Card.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/81c8e/WebUI-MenuBar_SD-Card.png 230w", "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/08a84/WebUI-MenuBar_SD-Card.png 460w", "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/5a190/WebUI-MenuBar_SD-Card.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d8d52b6f5d5d52d48f5b2c63628180b8/5a190/WebUI-MenuBar_SD-Card.png",
              "alt": "Web User Interface - 720p Series - Menu_Bar",
              "title": "Web User Interface - 720p Series - Menu_Bar",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the SD Card to open the SD cards directory in a new browser window or tab - all your snapshots and videos will be listed. Use your camera admin login to access the directory. You can also access your SD card with third-party software.`}</p>
    <h3 {...{
      "id": "snapshot-to-sd---take-a-snapshot-and-save-it-to-the-internal-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#snapshot-to-sd---take-a-snapshot-and-save-it-to-the-internal-sd-card",
        "aria-label": "snapshot to sd   take a snapshot and save it to the internal sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Snapshot to SD - Take a Snapshot and save it to the Internal SD Card`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/5a190/WebUI-MenuBar_Snapshot2SD.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.6521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOElEQVQI1wXBQQrAAAgDsP3/LihYUapT9solT1WRjAgRUdXM/MzCjN3NbhLucJB9dwE4MDO77+7+cGQljSPx+LUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8f1803f6fd5079da99ddc8126fdadcd/e4706/WebUI-MenuBar_Snapshot2SD.avif 230w", "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/d1af7/WebUI-MenuBar_Snapshot2SD.avif 460w", "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/c9875/WebUI-MenuBar_Snapshot2SD.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f8f1803f6fd5079da99ddc8126fdadcd/a0b58/WebUI-MenuBar_Snapshot2SD.webp 230w", "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/bc10c/WebUI-MenuBar_Snapshot2SD.webp 460w", "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/d00b9/WebUI-MenuBar_Snapshot2SD.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8f1803f6fd5079da99ddc8126fdadcd/81c8e/WebUI-MenuBar_Snapshot2SD.png 230w", "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/08a84/WebUI-MenuBar_Snapshot2SD.png 460w", "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/5a190/WebUI-MenuBar_Snapshot2SD.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f8f1803f6fd5079da99ddc8126fdadcd/5a190/WebUI-MenuBar_Snapshot2SD.png",
              "alt": "Web User Interface - 720p Series - Menu_Bar",
              "title": "Web User Interface - 720p Series - Menu_Bar",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Snapshot to save a single JPG snapshot of the current video frame to the internal `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/SD_Card_Access/"
      }}>{`SD Card`}</a>{`. When you are using the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Multimedia/Video/"
      }}>{`ActiveX Video Plugin`}</a>{` the snapshot will be saved locally in the subfolder Snap under the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Video_Menu_Bar/"
      }}>{`Record Path`}</a>{` (only ActiveX Video Plugin). Otherwise the snapshot will be opened in a new browser window or tab and can be saved from there. You might have to set the cameras address (local IP or DDNS address) as a trusted site inside the Internet Options of your Internet Explorer to allow write access to your hard disk!`}</p>
    <h3 {...{
      "id": "h264--mjepeg---switch-between-the-cameras-h264-stream-and-the-plugin-free-mjpeg-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#h264--mjepeg---switch-between-the-cameras-h264-stream-and-the-plugin-free-mjpeg-stream",
        "aria-label": "h264  mjepeg   switch between the cameras h264 stream and the plugin free mjpeg stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`h.264 / MJEPEG - Switch between the cameras h.264 stream and the plugin-free MJPEG stream`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7983a162cd258794ff511649afaf9a78/5a190/WebUI-MenuBar_h264.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.217391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAANUlEQVQI1x3GsQkAMQwDwN+/cvOdJSIkjMcM5Kr7jmQ7NklJcU7VX9WAn+5Gw8nukgSQ95m5c/EljwLgmmoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7983a162cd258794ff511649afaf9a78/e4706/WebUI-MenuBar_h264.avif 230w", "/en/static/7983a162cd258794ff511649afaf9a78/d1af7/WebUI-MenuBar_h264.avif 460w", "/en/static/7983a162cd258794ff511649afaf9a78/c9875/WebUI-MenuBar_h264.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7983a162cd258794ff511649afaf9a78/a0b58/WebUI-MenuBar_h264.webp 230w", "/en/static/7983a162cd258794ff511649afaf9a78/bc10c/WebUI-MenuBar_h264.webp 460w", "/en/static/7983a162cd258794ff511649afaf9a78/d00b9/WebUI-MenuBar_h264.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7983a162cd258794ff511649afaf9a78/81c8e/WebUI-MenuBar_h264.png 230w", "/en/static/7983a162cd258794ff511649afaf9a78/08a84/WebUI-MenuBar_h264.png 460w", "/en/static/7983a162cd258794ff511649afaf9a78/5a190/WebUI-MenuBar_h264.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7983a162cd258794ff511649afaf9a78/5a190/WebUI-MenuBar_h264.png",
              "alt": "Web User Interface - 720p Series - Menu_Bar",
              "title": "Web User Interface - 720p Series - Menu_Bar",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can switch between the cameras h.264 and MJPEG video stream - the active mode will be displayed in red. This is a quick-access button for the video stream toggle found in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Multimedia/Video/"
      }}>{`Video Menu`}</a>{`. In general, the bandwidth requirements for the MJPEG stream will be higher and in low connectivity situations, the stream might drop some frames. The h.264 stream can have a much higher compression (depending on your settings in the Video Menu) - leading to lower bandwidth requirements. It also needs a video plugin to be displayed - please check the video menu to switch between the ActiveX, QuickTime and Flash plugin.`}</p>
    <h3 {...{
      "id": "help---direct-link-to-our-online-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#help---direct-link-to-our-online-documentation",
        "aria-label": "help   direct link to our online documentation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Help - Direct Link to our Online Documentation`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/5a190/WebUI-MenuBar_Help.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.6521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOUlEQVQI1x3BiQ3AMAgEsO6/QCoS0CGOJ1tWqv3APRiMMDPAK+u+y0SC3CIA9BxVJTkz+svM7uruD3u8Jb3L/lt1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/e4706/WebUI-MenuBar_Help.avif 230w", "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/d1af7/WebUI-MenuBar_Help.avif 460w", "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/c9875/WebUI-MenuBar_Help.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/a0b58/WebUI-MenuBar_Help.webp 230w", "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/bc10c/WebUI-MenuBar_Help.webp 460w", "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/d00b9/WebUI-MenuBar_Help.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/81c8e/WebUI-MenuBar_Help.png 230w", "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/08a84/WebUI-MenuBar_Help.png 460w", "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/5a190/WebUI-MenuBar_Help.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6b2c13fcdbf2c25f1c3080f02bf5a325/5a190/WebUI-MenuBar_Help.png",
              "alt": "Web User Interface - 720p Series - Menu_Bar",
              "title": "Web User Interface - 720p Series - Menu_Bar",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking on Help will bring you to our constantly expanding `}<a href="http://hd.faq.instar.de/" target="_blank" rel="noopener noreferrer">{`Online FAQ Section`}</a>{` on `}<a parentName="p" {...{
        "href": "http://www.instar.de"
      }}>{`www.instar.de`}</a>{`.`}</p>
    <h3 {...{
      "id": "recording-to-sd---start-a-manual-video-recording-and-save-it-to-the-internal-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#recording-to-sd---start-a-manual-video-recording-and-save-it-to-the-internal-sd-card",
        "aria-label": "recording to sd   start a manual video recording and save it to the internal sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recording to SD - Start a Manual Video Recording and Save it to the Internal SD Card`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2945a8dc92045ba745d8178dc5bcc054/5a190/WebUI-MenuBar_Recording2SD.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.6521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAANUlEQVQI1x3BgQ2AQAgDQPffAKUkFUrjliZ/d5F8u2emqkhK+u6oiJ7pI/NBJgDbOKRdr+0fflslx4TXV18AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2945a8dc92045ba745d8178dc5bcc054/e4706/WebUI-MenuBar_Recording2SD.avif 230w", "/en/static/2945a8dc92045ba745d8178dc5bcc054/d1af7/WebUI-MenuBar_Recording2SD.avif 460w", "/en/static/2945a8dc92045ba745d8178dc5bcc054/c9875/WebUI-MenuBar_Recording2SD.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2945a8dc92045ba745d8178dc5bcc054/a0b58/WebUI-MenuBar_Recording2SD.webp 230w", "/en/static/2945a8dc92045ba745d8178dc5bcc054/bc10c/WebUI-MenuBar_Recording2SD.webp 460w", "/en/static/2945a8dc92045ba745d8178dc5bcc054/d00b9/WebUI-MenuBar_Recording2SD.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2945a8dc92045ba745d8178dc5bcc054/81c8e/WebUI-MenuBar_Recording2SD.png 230w", "/en/static/2945a8dc92045ba745d8178dc5bcc054/08a84/WebUI-MenuBar_Recording2SD.png 460w", "/en/static/2945a8dc92045ba745d8178dc5bcc054/5a190/WebUI-MenuBar_Recording2SD.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2945a8dc92045ba745d8178dc5bcc054/5a190/WebUI-MenuBar_Recording2SD.png",
              "alt": "Web User Interface - 720p Series - Menu_Bar",
              "title": "Web User Interface - 720p Series - Menu_Bar",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Record to manually start a video recording. The video will be saved in the subfolder Record under the set `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Video_Menu_Bar/"
      }}>{`Record Path`}</a>{` (only `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Multimedia/Video/"
      }}>{`ActiveX Video Plugin`}</a>{`). You will see the red indicator in the video menu when the camera is recording. You might have to set the cameras address (local IP or DDNS address) as a trusted site inside the Internet Options of your Internet Explorer to be give write access to your hard disk! `}<strong parentName="p">{`Other browsers`}</strong>{` allow you to record videos directly to the internal SD Card.`}</p>
    <h3 {...{
      "id": "alarm-areas---activatedeactivate-your-alarm-areas-used-for-the-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-areas---activatedeactivate-your-alarm-areas-used-for-the-motion-detection",
        "aria-label": "alarm areas   activatedeactivate your alarm areas used for the motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Areas - Activate/Deactivate your Alarm Areas used for the Motion Detection.`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/66473fb0bbf9e14ed156e55652a471d7/5a190/WebUI-MenuBar_Alarm-Area.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.217391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOElEQVQI1wXBgRHAIAgDwO6/gQo5MUmlW/b/qSqSkgDs2ra/MTAnbR6SjFiZKam7IyIBZ77Svf0Df9clzAG07x8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66473fb0bbf9e14ed156e55652a471d7/e4706/WebUI-MenuBar_Alarm-Area.avif 230w", "/en/static/66473fb0bbf9e14ed156e55652a471d7/d1af7/WebUI-MenuBar_Alarm-Area.avif 460w", "/en/static/66473fb0bbf9e14ed156e55652a471d7/c9875/WebUI-MenuBar_Alarm-Area.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/66473fb0bbf9e14ed156e55652a471d7/a0b58/WebUI-MenuBar_Alarm-Area.webp 230w", "/en/static/66473fb0bbf9e14ed156e55652a471d7/bc10c/WebUI-MenuBar_Alarm-Area.webp 460w", "/en/static/66473fb0bbf9e14ed156e55652a471d7/d00b9/WebUI-MenuBar_Alarm-Area.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66473fb0bbf9e14ed156e55652a471d7/81c8e/WebUI-MenuBar_Alarm-Area.png 230w", "/en/static/66473fb0bbf9e14ed156e55652a471d7/08a84/WebUI-MenuBar_Alarm-Area.png 460w", "/en/static/66473fb0bbf9e14ed156e55652a471d7/5a190/WebUI-MenuBar_Alarm-Area.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/66473fb0bbf9e14ed156e55652a471d7/5a190/WebUI-MenuBar_Alarm-Area.png",
              "alt": "Web User Interface - 720p Series - Menu_Bar",
              "title": "Web User Interface - 720p Series - Menu_Bar",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking on Alarm activates or deactivates the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Areas/"
      }}>{`Alarm Areas Settings`}</a>{`. If the motion detection is armed the number will be displayed in red.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      